@import "../../assets/styles/variables";

.edittopic-wrapper {
  min-height: 100%;

  .flexible-container {
    padding-left: 200px;
    min-height: calc(100vh - 72px);
  }

  &-content {
    padding: 24px 16px;
  }

  &-header {
    margin-bottom: 24px;
  }

  .create-cta {
    color: $white;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    padding: 8px 16px;
    background-color: $blue-primary;
  }

  .spaced-button {
    margin-right: 16px;
  }

  .input-editor {
    margin-bottom: 32px;
    border: 1px solid $gray-light;

    &-field {
      min-height: 300px;
    }
  }
}
