@import '../../assets/styles/variables';

.sidebar-primary {
  top: 72px;
  left: 0px;
  width: 200px;
  z-index: 999;
  overflow-y: auto;
  position: absolute;
  padding: 40px 16px;
  background-color: $white;
  height: calc(100vh - 72px);
  border-right: 1px solid $gray-light;

  &-link {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    width: fit-content;
    margin-bottom: 24px;
    color: $blue-secondary;

    &:hover,
    &:focus {
      color: $peach-primary;
    }
  }

  &-active{
    color: $peach-primary;
  }
}

.active{
  color: $peach-primary;
}

.icon-size{
  height: 20px;
  width: 20px;
}
#test::-webkit-scrollbar-track
  {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
  }

#test::-webkit-scrollbar
  {
    width: 6px;
    background-color: #F5F5F5;
  }

#test::-webkit-scrollbar-thumb
  {
    border-radius: 10px;
    background-color: #a8a8a8;
  }