@import '../../assets/styles/variables';
@import '../../assets//styles/mixins';

.flexible-table {
  width: 100%;
  font-size: 14px;
  line-height: 20px;
  border-collapse: collapse;
  border: 1px solid $gray-light;

  th,
  td {
    padding: 16px 8px;
    border-collapse: collapse;
    border: 1px solid $gray-light;

    .dropdown {
      text-align: center;
      .drop {
        color: black !important;
        background-color: transparent !important;
        padding: 0 !important;
        border-radius: 0px;
        border: none;
        &-menu {
          transform: translate(-50px, 30px) !important;
          max-height: 150px;
          overflow-y: auto;
        }
      }
      .btn-primary:active,
      :focus {
        border: none;
        box-shadow: none !important;
      }
    }
  }

  // td:first-child{
  //   text-align: center;
  // }

  th {
    color: $blue-primary;
    background-color: $peach-primary;

    .sort {
      margin-left: 8px;
    }
  }

  th.pointer {
    cursor: pointer;
  }

  // td {
  //   padding: 16px 8px;
  //   color: $blue-secondary;
  //   .drop {
  //     border: none;
  //     border-radius: 0px;
  //     background-color: $blue-primary;
  //     padding: 0.3rem 0.5rem;
  //     &-menu {
  //       max-height: 150px;
  //       overflow-y: auto;
  //     }
  //   }
  // }

  &-button {
    color: $blue-primary;
    background-color: transparent;
  }
  &-button-width {
    width: 100%;
  }
}

.flexible-table-actions {
  &-disabled {
    // cursor: default;
    opacity: 50%;
    button:not(:disabled),
    [type='button']:not(:disabled),
    [type='reset']:not(:disabled),
    [type='submit']:not(:disabled) {
      cursor: default !important;
    }
  }
}

.flexible-paginator {
  margin-top: 32px;

  &-button {
    font-size: 14px;
    margin: 0px 8px;
    font-weight: 600;
    padding: 4px 12px;
    line-height: 18px;
    color: $blue-primary;
    background-color: transparent;
    border: 1px solid $blue-primary;

    &:hover:not(:disabled),
    &:focus:not(:disabled),
    &:active:not(:disabled) {
      color: $white;
      background-color: $blue-primary;
    }

    &:disabled {
      opacity: 0.5;
    }
  }

  .pageNumberInput {
    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type='number'] {
      -moz-appearance: textfield;
    }

    // padding: 3px;
    // border-radius: 5px;
    margin-left: 5px;
    margin-right: 5px;
    border-width: 1px;
    border-color: #00000038;
    border-radius: 5px;
    padding-left: 10px;
  }

  .paginationText {
    font-size: 1.2em;
    margin-left: 2em;
    margin-right: 2em;

    .paginationSplash {
      margin-left: 10px;
      margin-right: 10px;
      transform: scale(1.5);
      display: inline-block;
    }
  }
}
