@import '../../assets/styles/variables';

.login-wrapper {
  min-height: 100vh;

  .flexible-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 72px);
  }

  &-content {
    width: 100%;
    max-width: 500px;
    background-color: $peach-primary;
  }
}

.wrapper-content {
  &-header {
    padding: 16px 32px;
    background-color: $blue-primary;
  }

  &-title {
    color: $white;
    font-size: 24px;
    font-weight: 500;
    line-height: 30px;
    margin-bottom: 0px;
    text-transform: uppercase;
  }

  &-body {
    padding: 48px 32px;

    .button-submit {
      margin-top: 16px;
    }
  }
}
