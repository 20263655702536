@import '../../assets/styles/variables';

.input {
  &-wrapper {
    margin-bottom: 32px;
  }

  &-checkbox {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    padding: 8px 16px;
    margin-right: 8px;
    border: 1px solid $gray-light;
  }

  &-label {
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    margin-bottom: 4px;
    color: $blue-primary;
  }
}
