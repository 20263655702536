@import '../../assets/styles/variables';

.subheader-primary {
  padding: 48px 24px;
  margin-bottom: 16px;
  background-color: $blue-primary;

  &-title {
    color: $white;
    font-size: 40px;
    line-height: 48px;
    text-transform: uppercase;
  }

  &-subtitle {
    max-width: 75%;
    font-size: 26px;
    line-height: 34px;
    text-align: center;
    color: $peach-primary;
    text-transform: uppercase;
  }
}
