@import '../../assets/styles/variables';

.testimonial-wrapper {
  min-height: 100%;

  .flexible-container {
    padding-left: 200px;
    min-height: calc(100vh - 72px);
  }

  &-content {
    padding: 24px 16px;
  }

  &-header {
    margin-bottom: 24px;
  }

  .create-cta {
    color: $white;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    padding: 8px 16px;
    background-color: $blue-primary;
  }

  .spaced-button {
    margin-right: 16px;
  }

  .input-editor {
    margin-bottom: 32px;
    border: 1px solid $gray-light;

    &-field {
      min-height: 300px;
    }
  }
}

.inactive-confirm-modal {
  .modal-dialog {
    height: calc(100% - 3.5rem);
    display: flex;
    align-items: center;
  }

  &-body {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  p {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
  }

  button {
    background-color: #fff;
    border: 1px solid #43536e;
    padding: 10px 24px;
    margin-left: 16px;

    &:hover, &:active{
      background-color: #43536e;
      color: #fff;
    }
  }

  .modal-body {
    padding: 24px 30px;
  }
}

.schedule {
  .label {
    font-size: 1rem;
    color: #172738;
    font-weight: 600;
    text-align: left;

    span {
      color: #d3776e;
    }
  }

  .date-input {
    .input {
      display: flex;
      width: 100%;
      padding: 0.35rem 0.4rem;
      outline: none;
      background-color: #fff;

      border-radius: 5px;
      border: 1px solid #8ca4d5;

      font-size: 1rem;
      color: #122839;

      &::-webkit-calendar-picker-indicator {
        opacity: 0.6;

        &:hover {
          opacity: 1;
        }
      }
    }
  }

  .time-slots {
    &-list {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      &-item {
        padding: 0.5rem;
        border: 1px solid #43536e;
        border-radius: 6px;
        cursor: pointer;
        transition-duration: 300ms;

        p {
          font-size: 1rem;
          color: #43536e;
          font-weight: 600;
          transition-duration: 300ms;
          margin-top: 0;
          margin-bottom: 0;
        }

        &.selected {
          background-color: #43536e;

          p {
            color: #ffffff;
          }
        }
      }
    }
  }
}
