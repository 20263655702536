@import '../../assets/styles/variables';

.appointments-wrapper {
    min-height: 100%;

    .flexible-container {
        padding-left: 200px;
        min-height: calc(100vh - 72px);

        div {
            .btn {
                color: #fff;
            }
            .btn-all {
                background-color: #007bff;
                border-color: #007bff;

                &:active,
                &:focus,
                &:hover {
                    background-color: #0063cc;
                    border-color: #0063cc;
                }

                &:active,
                &:focus {
                    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
                }
            }
            .btn-upcoming {
                background-color: #45cca5;
                border-color: #45cca5;
                &:active,
                &:focus,
                &:hover {
                    background-color: #30b08c;
                    border-color: #30b08c;
                }

                &:active,
                &:focus {
                    box-shadow: 0 0 0 0.2rem rgba(69, 204, 165, 0.5);
                }
            }
            .btn-previous {
                background-color: #f1bf28;
                border-color: #f1bf28;
                &:active,
                &:focus,
                &:hover {
                    background-color: #f0b80f;
                    border-color: #f0b80f;
                }

                &:active,
                &:focus {
                    box-shadow: 0 0 0 0.2rem rgba(241, 191, 40, 0.5);
                }
            }

            .btn-export {
                float: right;
                background-color: #094204;
                border-color: #094204;
                &:active,
                &:focus,
                &:hover {
                    background-color: #094204;
                    border-color: #094204;
                }

                &:active,
                &:focus {
                    box-shadow: 0 0 0 0.2rem rgba(241, 191, 40, 0.5);
                }
            }

        }
    }

    &-content {
        padding: 24px 16px;
        .flexible-table th, .flexible-table td {
            max-width: 150px;
            overflow-wrap: break-word;
            white-space: pre-wrap;
        }
    }

    &-header {
        margin-bottom: 24px;
    }

    .create-cta {
        cursor: pointer;
        color: $white;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        padding: 8px 16px;
        background-color: $blue-primary;
    }
}

.modal-content {
    .flexible-table {
        td{
            max-width: 400px;
            overflow-wrap: break-word;
            white-space: pre-wrap;
        }
    }
}