$gray-light: #f2f2f2;
$blue-primary: #43536e;

.input {
    &-wrapper {
        margin-bottom: 32px;
    }

    &-dropdown {
        margin-bottom: 32px;

        &-field {
            background-color: white;
            width: 100%;
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            padding: 6px 16px;
            border: 1px solid $gray-light;
        }

        &-label {
            font-size: 14px;
            font-weight: 600;
            line-height: 18px;
            margin-bottom: 4px;
            color: $blue-primary;
        }
    }
}

.appointment-status-confirm-modal {
    .modal-dialog {
        height: calc(100% - 3.5rem);
        max-width: 600px;
        display: flex;
        align-items: center;
    }

    &-body {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    p {
        font-size: 16px;
        font-weight: 600;
        margin: 0;
        color: #525558;
    }

    button {
        background-color: #fff;
        border: 1px solid #43536e;
        padding: 10px 24px;
        margin-left: 16px;
        color: #43536e;
        font-size: 16px;

        &:focus,
        &:active {
            color: #fff;
            background-color: #43536e;
        }
    }

    .modal-body {
        padding: 24px 30px;
    }
}
