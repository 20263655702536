@import '../../assets/styles/variables';

.programs-wrapper {
  min-height: 100%;

  .flexible-container {
    padding-left: 200px;
    min-height: calc(100vh - 72px);
  }

  &-content {
    padding: 24px 16px;
  }

  &-header {
    margin-bottom: 24px;
  }

  .create-cta {
    cursor: pointer;
    color: $white;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    padding: 8px 16px;
    background-color: $blue-primary;
  }

  .tabs {
    display: flex;
    padding: 0 16px;
    .tab {
      color: $blue-primary;
      border: 1px solid $blue-primary;
      padding: 8px 28px;
      border-radius: 4px;
      cursor: pointer;

      &.active{
        background-color: $blue-primary;
        color: #fff;
        cursor: default;
      }
    }
  }
}
