@import '../../assets/styles/variables';

.editpost-wrapper {
  min-height: 100%;

  .color-input {
    padding: 0;
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    padding: 0.1rem 0.5rem;
    border: 1px solid #f2f2f2;
    min-height: 38px;
  }

  .editor-label {
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    margin-bottom: 4px;
    color: #43536e;
  }
  .flexible-container {
    padding-left: 200px;
    min-height: calc(100vh - 72px);
  }

  &-content {
    padding: 24px 16px;
  }

  &-header {
    margin-bottom: 24px;
  }

  .create-cta {
    color: $white;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    padding: 8px 16px;
    background-color: $blue-primary;
  }

  .spaced-button {
    margin-right: 16px;
  }

  .input-editor {
    margin-bottom: 32px;
    border: 1px solid $gray-light;

    &-field {
      min-height: 300px;
    }
  }
}
