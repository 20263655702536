@import '../../assets/styles/variables';

.button {
  &-secondary {
    font-size: 16px;
    min-width: 125px;
    font-weight: 600;
    line-height: 20px;
    padding: 8px 16px;
    color: $blue-primary;
    text-transform: uppercase;
    background-color: transparent;
    border: 1px solid $blue-primary;

    &:hover:not(:disabled),
    &:focus:not(:disabled),
    &:active:not(:disabled) {
      color: $white;
      background-color: $blue-primary;
    }
  }
}
