@import '../../assets/styles/variables';

.input-datetime {
  & > .form-control {
    border: none;
    outline: none;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    padding: 0px !important;
    height: initial !important;


    &:hover,
    &:focus,
    &:active {
      outline: none;
      box-shadow: none;
    }
  }
}
.form-control:disabled{
  background-color: transparent !important;
  opacity: 1;
} 
.input-disabled{
  background-color: rgba(239, 239, 239, 0.3) !important;
}
