@import '../../assets/styles/variables';

.clinic-wrapper {
  min-height: 100%;

  .flexible-container {
    padding-left: 200px;
    min-height: calc(100vh - 72px);
  }
  .editor-label {
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    margin-bottom: 4px;
    color: #43536e;
  }
  .sectionHeading {
    color: black;
    font-size: xx-large;
    margin-bottom: 1.5em;
    margin-top: 1em;
  }

  &-content {
    padding: 24px 16px;
  }

  &-header {
    margin-bottom: 24px;
  }

  .create-cta {
    color: $white;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    padding: 8px 16px;
    background-color: $blue-primary;
  }

  .spaced-button {
    margin-right: 16px;
  }

  .input-editor {
    margin-bottom: 32px;
    border: 1px solid $gray-light;

    &-field {
      min-height: 300px;
    }
  }

  .delete-button {
    font-size: 12px;
    min-width: unset;
    padding: 4px 8px;
    line-height: 16px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    position: absolute;
    right: 1em;
    top: 2.5em;
  }

  .specialization-row {
    position: relative;
  }

  .add-more-button {
    font-size: 12px;
    min-width: unset;
    padding: 4px 8px;
    line-height: 16px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
}
