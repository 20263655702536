// Generic Theme
$white: #ffffff;

// Light Theme
$gray-light: #f2f2f2;
$peach-primary: #e7bfad;
$peach-secondary:#f9eeea;

// Dark Theme
$blue-primary: #43536e;
$blue-secondary: #122839;
