@mixin mobile {
  @media only screen and (min-device-width: 320px) and (max-device-width: 640px) and (-webkit-min-device-pixel-ratio: 2) {
    @content;
  }
}

@mixin tablet {
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    @content;
  }
}

@mixin large-screen {
  @media screen and (min-device-width: 1500px) {
    @content;
  }
}

@mixin large-md-screen {
  @media screen and (min-device-width: 1500px) and (max-device-width: 1920px) and (-webkit-min-device-pixel-ratio: 1) {
    @content;
  }
}

@mixin iphone5 {
  @media screen and (min-device-width: 320px) and (max-device-width: 359px) {
    @content;
  }
}

@mixin window-desktop {
  @media only screen and (min-device-width: 1023px) and (max-device-width: 1200px) and (-webkit-min-device-pixel-ratio: 1) {
    @content;
  }
}
