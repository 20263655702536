@import '../../assets/styles/variables';

.button {
  &-primary {
    color: $white;
    font-size: 16px;
    min-width: 125px;
    font-weight: 600;
    line-height: 20px;
    padding: 8px 16px;
    text-transform: uppercase;
    background-color: $blue-primary;
  }
  
}
.disabled {
  opacity: .5;
}