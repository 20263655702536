
@import '../../assets/styles/variables';

.emptystate-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 400px;
  background-color: $gray-light;
  border-radius: 10px;

  // img-icon
  .img-icon {
    width: 120px;
    margin-bottom: 10px;
  }

  // title style
  .title4 {
    color: $peach-primary;
    opacity: 1.0;
    font-family: 'Open Sans', sans-serif !important;
    font-size: 1.4rem;
  }

  .back-button {
    width: 200px;
    height: 50px;
    background-color: $peach-primary;
  }
}
