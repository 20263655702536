@import "../../assets/styles/variables";

.editUser-wrapper {
  min-height: 100%;

  .flexible-container {
    padding-left: 200px;
    min-height: calc(100vh - 72px);
  }

  &-membership-content {
    padding: 24px 16px;
    margin-bottom: 20px;
    margin-top: -40px;
  }

  &-content {
    padding: 24px 16px;
  }

  &-header {
    margin-bottom: 24px;
  }

  .create-cta {
    color: $white;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    padding: 8px 16px;
    background-color: $blue-primary;
  }

  .spaced-button {
    margin-right: 16px;
  }

  .input-editor {
    margin-bottom: 32px;
    border: 1px solid $gray-light;

    &-field {
      min-height: 300px;
    }
  }

  .input-wrapper-display {
    width: fit-content;
    background-color: transparent;

    .display-image {
      width: 144px;
      height: 144px;
      object-fit: cover;
      border-radius: 50%;
    }
  }
}

.input-replicator {
  margin-bottom: 32px;

  &-title {
    font-size: 16px;
    margin-bottom: 16px;
    color: $blue-primary;
  }

  &-fieldset {
    margin-bottom: 16px;

    .input-wrapper {
      flex: 1;
      margin-bottom: 0px;
      margin-right: 16px;
    }

    .button-secondary {
      font-size: 12px;
      min-width: unset;
      padding: 4px 8px;
      line-height: 16px;
      width: fit-content;
    }
  }

  .button-secondary {
    font-size: 12px;
    min-width: unset;
    padding: 4px 8px;
    line-height: 16px;
    width: fit-content;
  }
}
