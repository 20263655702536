@import '../../assets/styles/variables';

.header-primary {
  padding: 16px 40px;
  background-color: $peach-primary;
}

.header-logo {
  max-height: 40px;
}

.header-profile {
  &-trigger {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    color: $blue-secondary;
    background-color: transparent;

    svg {
      margin-left: 8px;
    }
  }

  &-dropdown {
    right: 6px;
    min-width: 125px;
    background-color: $white;
  }

  .profile-dropdown-item {
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    padding: 8px 16px;
    color: $blue-secondary;
  }
}
