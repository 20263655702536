@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');

@import '../../../node_modules/bootstrap/scss/bootstrap.scss';

@import './variables';

* {
  outline: none !important;
  text-decoration: none !important;

  &:hover,
  &:visited,
  &:active,
  &:focus {
    outline: none !important;
    text-decoration: none !important;
  }
}

body {
  margin: 0px auto;
  max-width: 1680px;
  min-height: 100vh;
  position: relative;
  background-color: $gray-light;
  font-family: 'Open Sans', sans-serif !important;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #a8a8a8;
  }
}

#root {
  min-height: 100vh;
}

.flexible-container {
  width: 100%;
  // max-width: 1440px;
  background-color: $white;

  &--centered {
    margin: 0px auto;
  }
}

button {
  border: 0px;
  box-shadow: none;
}

.danger-button {
  color: red;
  border: 1px solid red;

  &:hover:not(:disabled),
  &:focus:not(:disabled),
  &:active:not(:disabled) {
    background-color: red;
  }
}

.success-button {
  color: green;
  border: 1px solid green;

  &:hover:not(:disabled),
  &:focus:not(:disabled),
  &:active:not(:disabled) {
    background-color: green;
  }
}

.input-wrapper-display {
  margin-bottom: 16px;
  object-fit: contain;
  background-color: $gray-light;

  .display-image {
    width: 100%;
    height: auto;
  }
}

.spinner-container {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  z-index: 1002;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.not-rounded {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  // @include mobile {
  //   border-top-left-radius: 0px;
  //   border-bottom-left-radius: 0px;
  //   position: fixed;

  //   .spinner {
  //     position: static;
  //   }
  // }
}

.spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 60px;
  width: 60px;
  margin: 0px auto;
  -webkit-animation: rotation 0.6s infinite linear;
  -moz-animation: rotation 0.6s infinite linear;
  -o-animation: rotation 0.6s infinite linear;
  animation: rotation 0.6s infinite linear;
  border-left: 6px solid $peach-secondary;
  border-right: 6px solid $peach-secondary;
  border-bottom: 6px solid $peach-secondary;
  border-top: 6px solid $peach-primary;
  border-radius: 100%;
}
.page-num {
  background-color: #e7bfad !important;
  color: #43536e !important;
  border-radius: 10px !important;
  padding: 0.1rem 0.6rem !important;
  margin-bottom: 1px;
  cursor: pointer;
}
.page-num-active {
  background-color: #43536e !important;
  color: #e7bfad !important;
  border-radius: 10px !important;
  padding: 0.1rem 0.6rem !important;
  margin-bottom: 1px;
  cursor: pointer;
}
@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}
@-moz-keyframes rotation {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(359deg);
  }
}
@-o-keyframes rotation {
  from {
    -o-transform: rotate(0deg);
  }
  to {
    -o-transform: rotate(359deg);
  }
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
