@import '../../assets/styles/variables';

.vertical-table {
  width: 100%;
  font-size: 14px;
  line-height: 20px;
  border-collapse: collapse;
  border: 1px solid $gray-light;

  th,
  td {
    padding: 16px 8px;
    border-collapse: collapse;
    border: 1px solid $gray-light;

    &.title {
        font-weight: 600;
    }

    .dropdown {
      text-align: center;
      .drop {
        color: black !important;
        background-color: transparent !important;
        padding: 0 !important;
        border-radius: 0px;
        border: none;
        &-menu {
          transform: translate(-50px, 30px) !important;
          max-height: 150px;
          overflow-y: auto;
        }
      }
      .btn-primary:active,
      :focus {
        border: none;
        box-shadow: none !important;
      }
    }
  }

  // td:first-child{
  //   text-align: center;
  // }

  th {
    color: $blue-primary;
    background-color: $peach-primary;

    .sort {
      margin-left: 8px;
    }
  }

  th.pointer {
    cursor: pointer;
  }

  // td {
  //   padding: 16px 8px;
  //   color: $blue-secondary;
  //   .drop {
  //     border: none;
  //     border-radius: 0px;
  //     background-color: $blue-primary;
  //     padding: 0.3rem 0.5rem;
  //     &-menu {
  //       max-height: 150px;
  //       overflow-y: auto;
  //     }
  //   }
  // }

  &-button {
    color: $blue-primary;
    background-color: transparent;
  }
  &-button-width {
    width: 100%;
  }
}