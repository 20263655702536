@import '../../assets/styles/variables';

.messages-wrapper {
  min-height: 100%;

  .optinFileUploadLabel {
    border-radius: 4px;
    margin: 2px;
    cursor: pointer;
    background: #43536e;
    color: white;
    display: inline-block;
    padding: 0.5em;
    position: absolute;
    right: 1em;

    & input[type='file'] {
      position: absolute;
      top: -1000px;
    }
    &:hover {
      background: #43536ec7;
    }
    &:active {
      background: #43536ec7;
    }
  }

  .flexible-container {
    padding-left: 200px;
    min-height: calc(100vh - 72px);
  }

  &-content {
    padding: 24px 16px;
  }

  &-header {
    margin-bottom: 24px;
  }

  .create-cta {
    cursor: pointer;
    color: $white;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    padding: 8px 16px;
    background-color: $blue-primary;
  }
}
