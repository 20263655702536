@import '../../assets/styles/variables';

.search-section {
  display: flex;
  align-items: center;
  position: relative;

//   @include mobile {
//     flex-direction: column;
//   }
  .input {

    line-height: 32px;
  }
  .custom-input {
    width: 100%;
    color: #8a8a8a;
    font-size: 18px;
    line-height: 25px;
    align-items: center;
    border: 1px solid black;
    padding: 10px 20px;

    &::placeholder {
      font-size: 18px;
    }

    // @include mobile {
    //   width: 100%;
    //   padding-right: 45px;
    // }
  }

  .sigup-btn {
    color: black;
    font-size: 16px;
    background-color: $peach-secondary;
    border: 2px solid $peach-secondary;
    padding: 10px 35px;

    // @include mobile {
    //   margin-top: 5px;
    //   position: absolute;
    //   background: transparent;
    //   border: none;
    //   right: 10px;
    //   padding: 0px;
    //   top: 9px;
    // }
  }

  .text-display-none {
    display: none;
    // @include mobile {
    //   display: block;
    // }
  }

  .text-display {
    display: block;
    // @include mobile {
    //   display: none;
    // }
  }
  
}
.create-cta {
  color: $white;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  padding: 8px 16px;
  background-color: $blue-primary;
}
