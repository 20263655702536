@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.input {
  &-Multidropdown {
    #scrollbar-style-multiselect::-webkit-scrollbar-track
    {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      background-color: #F5F5F5;
    }

    #scrollbar-style-multiselect::-webkit-scrollbar
    {
      width: 6px;
      background-color: #F5F5F5;
    }

    #scrollbar-style-multiselect::-webkit-scrollbar-thumb
    {
      border-radius: 10px;
      background-color: #a8a8a8;
    }
    height: 25vh;
    .no-show-list-items{
      display:none;
    }
    margin-bottom: 32px;

    &-Multifield {
      width: 100%;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      // padding: 6px 16px;
      border: 1px solid $gray-light;
      max-height: 17vh;
      overflow: scroll;
    }
    &-Multifield-Custom{
      width: 100%;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      // padding: 6px 16px;
      border: 1px solid $gray-light;
      max-height: 17vh;
      overflow: auto;
      padding-left: 16px;

      @include tablet{
        width: 150%;
      }
    }
    .scrollbar-width {
      ::-webkit-scrollbar {
        width: 18px !important;
      }
    }

    &-field-custom {
      width: 100%;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      border: 1px solid $gray-light;
      padding: 6px 4px 6px 16px;
    }

    &-Multilabel {
      font-size: 14px;
      font-weight: 600;
      line-height: 18px;
      margin-bottom: 4px;
      color: $blue-primary;
    }
    &-Multilist {
        list-style-type: none;
        text-decoration: none;
        height: 30px;
        display: flex;
        flex-direction: row;
        align-items: center;
        input{
          margin-right: 10%;
        }
    }
    &-Multiicon{
      font-size: 11px;
      font-weight: bolder;
    }
  }
}
