@import "../../assets/styles/variables";

.blogs-wrapper {
  // #testScroll::-webkit-scrollbar-track {
  //   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  //   background-color: #f5f5f5;
  // }

  // #testScroll::-webkit-scrollbar {
  //   width: 6px !important;
  //   background-color: #f5f5f5 !important;
  // }

  // #testScroll::-webkit-scrollbar-thumb {
  //   border-radius: 10px !important;
  //   background-color: #a8a8a8 !important;
  // }
  min-height: 100%;

  .flexible-container {
    padding-left: 200px;
    min-height: calc(100vh - 72px);
  }

  &-content {
    padding: 24px 16px;
  }

  &-header {
    margin-bottom: 24px;
    display: flex;
    .search {
      //height: 50px;
      flex: 1;

      input {
        width: 50%;
        height: 100%;
        border: 1px solid #a8a8a8;
        padding: 0rem 1rem;
      }
      .search-btn {
        padding: 0.5rem 1rem;
        margin-left: 1.5rem;
      }
    }
    .create-cta {
      cursor: pointer;
      color: $white;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      padding: 8px 16px;
      background-color: $blue-primary;
    }
  }
}
